import React from 'react'

import Layout from '../components/layout'

const DisclaimerPage = () => (
  <Layout className="my-3">
    <div className="text-center">
      <p>
        The website jeangelique.com is edited by Jens Wages, private person.
      </p>
      <p>Contact: oui @ jeangelique.com</p>
    </div>
  </Layout>
)

export default DisclaimerPage
